import { baseURL, getCommonParams, getIdToken } from './index';

import axios from 'axios';

// Axios

/**
 *
 * @param {{pageSize: number, pageNumber:number, column:string, order: string, statuses: string[],shipmentTypes:string[], shipmentTatCriteria: string[],  createdAtGtEq: ,createdAtLtEq}} parameters Parameters for the get Shipments API call
 * @returns
 */
export const getShipments = async ({
  pageSize,
  pageNumber,
  column,
  order,
  search,
  clientUid,
  statuses = [],
  shipmentTypes = [],
  pickupDateGtEq,
  pickupDateLtEq,
  deliveryDateGtEq,
  deliveryDateLtEq,
  createdAtGtEq,
  createdAtLtEq,
  actualDeliveryDateGtEq,
  actualDeliveryDateLtEq,
  actualPickupDateGtEq,
  actualPickupDateLtEq,
  expectedAttemptDateGtEq,
  expectedAttemptDateLtEq,
  scheduledAttemptDateGtEq,
  scheduledAttemptDateLtEq,
  shipmentTatCriteria = [],
  handoverModes = [],
  tags,
  serviceRequests,
}) => {
  return axios({
    baseURL,
    url: "/shipments",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      pageSize,
      pageNumber,
      column,
      order,
      search,
      clientUid,
      statuses,
      shipmentTypes,
      pickupDateGtEq,
      pickupDateLtEq,
      deliveryDateGtEq,
      deliveryDateLtEq,
      createdAtGtEq,
      createdAtLtEq,
      actualDeliveryDateGtEq,
      actualDeliveryDateLtEq,
      actualPickupDateGtEq,
      actualPickupDateLtEq,
      expectedAttemptDateGtEq,
      expectedAttemptDateLtEq,
      scheduledAttemptDateGtEq,
      scheduledAttemptDateLtEq,
      tags,
      serviceRequests,
    },
  }).then(response => response.data);
};

/**
 *
 * @param {number} waybillNumber Shipment's waybill number
 * @returns object
 */
export const getShipmentByWaybillNumber = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

/**
 *
 * @param {number} waybillNumber
 * @returns {number}
 */

export const cloneShipment = async waybillNumber => {
  try {
    const res = await axios({
      baseURL,
      url: `/shipments/${waybillNumber}/clone`,
      method: `POST`,
      headers: {
        Authorization: `bearer ${await getIdToken()}`,
      },
    });

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateShipment = payload => ({ payload });

/**
 *
 * @param {string} waybillNumber
 * @returns
 */
export const cancelShipment = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/cancel`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

export const createShipment = payload => ({ shipment: 1 });

/*
fetch(`${BASE_URL}/shipments/${waybillNumber}/boxes`)

*/

export const getBoxes = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/boxes`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

export const getItems = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/items`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

export const getServiceRequests = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/service-requests`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

export const createServiceRequest = async (waybillNumber, serviceType) => {
  return axios({
    baseURL,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    url: `/shipments/${waybillNumber}/service-requests`,
    data: { serviceType },
  });
};
/*
fetch(`${BASE_URL}/shipments/{waybillNumber}/tracking-updates`)

*/

export const getPublicShipmentData = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/public`,
  }).then(response => response.data);
};

export const trackShipments = async waybillNumber =>
  axios({
    baseURL,
    url: `/shipments/${waybillNumber}/tracking-updates`,
  }).then(response => response.data);

/**
 *
 * @param {number} waybillNumber
 * @param {string} status
 * @returns
 */
export const updateShipmentStatus = async (waybillNumber, status) =>
  axios({
    baseURL,
    url: `/shipments/${waybillNumber}/change-status`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: { status },
  }).then(response => response.data);

export const createMultipleShipmentsFromFileUpload = async (
  file,
  clientUid,
) => {
  try {
    let data = new FormData();
    data.append("shipments", file, file.name);
    if (clientUid) {
      data.append("clientUid", clientUid);
    }
    await axios({
      baseURL,
      url: "/shipments/upload",
      method: "POST",
      headers: {
        Authorization: `bearer ${await getIdToken()}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    });

    return true;
  } catch (error) {
    console.log(`Unable to upload file:`, error);
    return false;
  }
};

export const createMultipleShipmentsFromValidatedJSON = async (
  data,
  clientUid,
  templateUid,
) => {
  try {
    if (!clientUid) {
      throw new Error('client context required');
    }
    return axios({
      baseURL,
      url: '/shipments/batch-create',
      method: 'POST',
      headers: {
        Authorization: `bearer ${await getIdToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      data: { items: data, clientUid, templateUid },
    });
  } catch (error) {
    throw new Error(`${error.message}`);
  }
};

export const getShipmentIngestionTemplates = async (clientUid) => {
  try {
    if (!clientUid) {
      return;
    }

    return axios({
      baseURL,
      url: '/ingestion-templates/shipments',
      method: 'GET',
      headers: {
        Authorization: `bearer ${await getIdToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      params: { clientUid },
    });
  } catch (error) {
    return { error: `${error.message}` };
  }
};

export const createReturnShipment = async ({
  waybillNumber,
  items,
  requestedPickupDate,
  reversePickupInfo,
}) => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/return`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      requestedPickupDate,
      reversePickupInfo,
      items,
    },
  }).then(response => response.data);
};

export const createReplacementShipment = async ({
  waybillNumber,
  items,
  requestedPickupDate,
  reversePickupInfo,
}) => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/replacement`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      requestedPickupDate,
      reversePickupInfo,
      items,
    },
  }).then(response => response.data);
};

export const reprintLabels = async ({ waybillNumber }) => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/label`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

export const shipmentRequestRTO = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/action`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      type: "rto_requested",
      payload: {},
    },
  });
};

export const rescheduleShipment = async ({ waybillNumber, date }) => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/reschedule`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      date,
    },
  }).then(response => response.data);
};
